import React from "react";
import { Checkbox, Tooltip } from "components/ui";
import { ACTIVE_INACTIVE_STATUS } from "constants/app.constant";

const ActiveInActiveCheckbox = ({
  dataObj,
  onActionHandle,
  dataKey,
  rowdata,
  fromCustomers = false
}) => {
  const activeValue = ACTIVE_INACTIVE_STATUS.find((item) => {
    if (dataObj === 1) {
      return item.key === "ACTIVE";
    } else {
      return item.key === "BLOCKED";
    }
  });

  return (
    <>
      <Tooltip title={dataObj ? `Want to ${fromCustomers ? "Blocked" : "InActive"}?` :  `Want to ${fromCustomers ? "UnBlocked" : "Active"}`} >
        <Checkbox
          checked={activeValue?.value}
          onChange={() => onActionHandle(dataKey, rowdata)}
        />
      </Tooltip>
    </>
  );
};

export default ActiveInActiveCheckbox;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Select, DatePicker } from "components/ui";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { APIS } from "constants/api.constant";
import { getApi } from "services/CommonService";

const MotionFilters = ({
  motionFilters,
  FilterMenu,
  setFilterValue,
  filterValue,
}) => {
  const [plantId, setPlantId] = useState("");
  const [filterComponent, setFilterComponent] = useState([]);

  const loadMaterialOption = (inputValue, resolve) => {
    const params = {
      search: inputValue,
      type: 3,
    };

    getApi(APIS.LIST_DATA, params).then((result) => {
      const options = result?.data?.data?.map((item) => ({
        label: `${item.description}(${item?.code})`,
        value: item?.id,
      }));
      resolve(options);
    });
  };

  const loadMaterialData = debounce(loadMaterialOption, 300);

  const loadCustomerOption = (inputValue, resolve) => {
    const params = {
      search: inputValue,
      type: 4,
    };

    getApi(APIS.LIST_DATA, params).then((result) => {
      const options = result?.data?.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      resolve(options);
    });
  };

  const loadCustomerData = debounce(loadCustomerOption, 300);

  const loadPlantsOption = (inputValue, resolve) => {
    const params = {
      search: inputValue,
      type: 1,
    };

    getApi(APIS.LIST_DATA, params).then((result) => {
      const options = result?.data?.data?.map((item) => ({
        label: `${item?.name}(${item?.code})`,
        value: item?.id,
      }));
      resolve(options);
    });
  };

  const loadPlants = debounce(loadPlantsOption, 300);

  const loadLocationOption = (inputValue, resolve) => {
    const params = {
      search: inputValue,
      type: 2,
      ...(plantId && { plantId: JSON.stringify([plantId]) }),
    };

    getApi(APIS.LIST_DATA, params).then((result) => {
      const options = result?.data?.data?.map((item) => ({
        label: `${item?.name}(${item?.code})`,
        value: item?.id,
      }));
      resolve(options);
    });
  };

  const loadLocation = debounce(loadLocationOption, 300);

  useEffect(() => {
    const selectedFilterArray = Object.keys(filterValue);

    const data = FilterMenu?.isFilterButton?.filters
      ?.map((it) => {
        if (selectedFilterArray.includes(it?.filterKey)) {
          return it;
        }
      })
      ?.filter((it) => !!it);
    setFilterComponent(data);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: !motionFilters ? 0 : 1,
        height: !motionFilters ? 0 : "auto",
        display: "block",
      }}
      transition={{ duration: 0.15 }}
    >
      <div className="mt-4 md:flex gap-4 w-full">
        {filterComponent?.map((filter, i) => {
          return (
            <div key={i} className={`w-full xs:mb-4 mt-4 sm:mb-4 md:w-1/5`}>
              {!!filter?.label && <label>{filter?.label}</label>}
              {filter.component === "select" && (
                <Select
                  key={filterValue[filter?.filterKey] ? "withValue" : "reset"}
                  autoComplete="off"
                  size="sm"
                  className={filter.className}
                  placeholder={filter.placeholder}
                  options={filter.options}
                  value={filterValue[filter?.filterKey] || null}
                  onChange={(selectedValue) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: selectedValue,
                    });
                  }}
                />
              )}
              {filter.component === "material-async-select" && (
                <Select
                  key={filterValue[filter?.filterKey] ? "withValue" : "reset"}
                  autoComplete="off"
                  placeholder={filter.placeholder}
                  defaultOptions
                  cacheOptions
                  size="sm"
                  style={filter.style}
                  value={filterValue[filter?.filterKey] || null}
                  loadOptions={loadMaterialData}
                  componentAs={AsyncSelect}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(event) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: event,
                    });
                  }}
                />
              )}
              {filter.component === "customer-async-select" && (
                <Select
                  key={filterValue[filter?.filterKey] ? "withValue" : "reset"}
                  autoComplete="off"
                  placeholder={filter.placeholder}
                  defaultOptions
                  cacheOptions
                  size="sm"
                  style={filter.style}
                  value={filterValue[filter?.filterKey] || null}
                  loadOptions={loadCustomerData}
                  componentAs={AsyncSelect}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(event) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: event,
                    });
                  }}
                />
              )}
              {filter?.component === "plants-async-select" && (
                <Select
                  key={filterValue[filter?.filterKey] ? "withValue" : "reset"}
                  autoComplete="off"
                  placeholder={filter.placeholder}
                  defaultOptions
                  cacheOptions
                  size="sm"
                  style={filter.style}
                  value={filterValue[filter?.filterKey] || null}
                  loadOptions={loadPlants}
                  componentAs={AsyncSelect}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(event) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: event,
                    });
                    setPlantId(event.value);
                  }}
                />
              )}
              {filter.component === "storage-location-async-select" && (
                <Select
                key={`${plantId}-${filterValue[filter?.filterKey] ? "withValue" : "reset"}`}
                  autoComplete="off"
                  placeholder={filter.placeholder}
                  defaultOptions
                  cacheOptions
                  size="sm"
                  style={filter.style}
                  value={filterValue[filter?.filterKey] || null}
                  loadOptions={loadLocation}
                  componentAs={AsyncSelect}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(event) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: event,
                    });
                  }}
                />
              )}
              {filter.component === "date-range-picker" && (
                <DatePicker.DatePickerRange
                  size="sm"
                  dateViewCount={2}
                  value={[
                    filterValue?.[filter?.filterKey1],
                    filterValue?.[filter?.filterKey2],
                  ]}
                  placeholder={filter?.placeholder}
                  onChange={(dateArray) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey1]: dateArray[0],
                      [filter?.filterKey2]: dateArray[1],
                    });
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default MotionFilters;

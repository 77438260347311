import { APIS } from "constants/api.constant";
import { postApi, getApi } from "./CommonService";

export const getAdminListing = async ({ page, limit, search, userType }) => {
  return await getApi(APIS.ADMIN_LIST, {
    // page: page + 1,
    limit: limit,
    search: search,
    userType: userType,
  }).then((res) => {
    return res;
  });
};

export const getPlaylistListing = async ({ page, limit, search, userType }) => {
  return await getApi(APIS.PLAYLIST_LIST, {
    page: page + 1,
    limit: limit,
    search: search,
  }).then((res) => {
    return res;
  });
};

export const getAdvertisment = async ({ page, limit, search, userType }) => {
  return await getApi(APIS.ADVERTISEMENT_LIST, {
    page: page + 1,
    limit: limit,
    search: search,
  }).then((res) => {
    return res;
  });
};

export const getPlantsList = async ({
  skip,
  limit,
  search,
  userType,
  type,
  status,
  plantId,
  storageLocationId,
  customerGroup,
}) => {
  return await getApi(APIS.LIST_DATA, {
    skip: skip,
    limit: limit,
    search: search,
    type: type,
    status: status,
    plantId: plantId,
    storageLocationId: storageLocationId,
    customerGroup: customerGroup,
  }).then((res) => {
    return res;
  });
};

export const getPrimarySales = async ({
  skip,
  limit,
  search,
  status,
  startDate,
  endDate,
  material,
  customer,
  deliveryCode,
}) => {
  return await getApi(APIS.PRIMARYT_SALES.GET_PRIMARY_SALES, {
    skip: skip,
    limit: limit,
    search: search,
    status: status,
    startDate: startDate,
    endDate: endDate,
    materialId: material,
    customerId: customer,
    deliveryCode: deliveryCode,
  }).then((res) => {
    return res;
  });
};

export const getSalesInvoice = async ({
  skip,
  limit,
  search,
  type,
  status,
  startDate,
  endDate,
  material,
  customer,
  deliveryCode,
}) => {
  return await getApi(APIS.SALES_INVOICE.GET_SALES_INVOICE, {
    skip: skip,
    limit: limit,
    search: search,
    type: type,
    status: status,
    startDate: startDate,
    endDate: endDate,
    materialId: material,
    customerId: customer,
    deliveryCode: deliveryCode,
  }).then((res) => {
    return res;
  });
};

export const getSalesBasedOnPicklist = async ({
  skip,
  limit,
  search,
  type,
  status,
  startDate,
  endDate,
  material,
  customer,
}) => {
  return await getApi(
    APIS.SALES_BASED_ON_PICKLIST.GET_SALES_BASED_ON_PICKLIST,
    {
      skip: skip,
      limit: limit,
      search: search,
      type: type,
      status: status,
      startDate: startDate,
      endDate: endDate,
      materialId: material,
      customerId: customer,
    }
  ).then((res) => {
    return res;
  });
};

export const getSecondarySales = async ({
  skip,
  limit,
  search,
  type,
  status,
  startDate,
  endDate,
  material,
  customer,
  deliveryCode,
}) => {
  return await getApi(APIS.SECONDARY_SALES.GET_SECONDARY_SALES, {
    skip: skip,
    limit: limit,
    search: search,
    type: type,
    status: status,
    startDate: startDate,
    endDate: endDate,
    materialId: material,
    customerId: customer,
    deliveryCode: deliveryCode,
  }).then((res) => {
    return res;
  });
};

export const getCustomersList = async ({
  skip,
  limit,
  search,
  type,
  status,
  plantId,
  customerGroup,
}) => {
  return await getApi(APIS.LIST_DATA, {
    skip: skip,
    limit: limit,
    search: search,
    type: type,
    status: status,
    plantId: plantId,
    customerGroup: customerGroup,
  }).then((res) => {
    return res;
  });
};
export const getHotProductsList = async ({
  skip,
  limit,
  search,
  type,
  status,
}) => {
  return await getApi(APIS.HOT_PRODUCTS.HOT_PRODUCTS_LISTING, {
    skip: skip,
    limit: limit,
    search: search,
    type: type,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getMaterialList = async ({
  skip,
  limit,
  search,
  customerGroup,
  type,
  status,
  prioritySku,
  conditionType,
}) => {
  return await getApi(APIS.LIST_DATA, {
    skip: skip,
    limit: limit,
    search: search,
    type: type,
    status: status,
    customerGroup: customerGroup,
    filterByPrioritySku: prioritySku,
    conditionType: conditionType,
  }).then((res) => {
    return res;
  });
};

export const getStorageLocationList = async ({
  skip,
  limit,
  search,
  type,
  status,
  plantId,
}) => {
  return await getApi(APIS.LIST_DATA, {
    skip: skip,
    limit: limit,
    search: search,
    type: type,
    status: status,
    plantId: plantId,
  }).then((res) => {
    return res;
  });
};

export const getExecutiveBoardListing = async ({
  skip,
  search,
  userType,
  limit,
  status,
}) => {
  return await getApi(APIS.LIST_USERS, {
    skip: skip,
    limit: limit,
    search: search,
    userType: userType,
    status: status,
  }).then((res) => {
    return res;
  });
};

// };

export const addUpdateuser = async (data) => {
  return await postApi(APIS.UPDATE_ADMIN, {
    name: data.name,
    email: data.email,
    countryCode: data.countryCode,
    phoneNumber: data.phoneNumber,
    code: data.code,
    userType: data.userType,
    plants: data.plants,
    storageLocations: data.storageLocations,
  });
};

// export const getUserById = async ({ id: id }) => {
//   return await getApi(APIS.USER_BY_ID, { id: Number(id) }).then((res) => res);
// };

export const getCommonAPIListing = async ({
  api,
  pageIndex,
  pageSize,
  totalPages,
  filterText,
  otherObj,
}) => {
  return await postApi(api, {
    pageIndex,
    pageSize,
    totalPages,
    filterText,
    ...otherObj,
  }).then((res) => {
    return res;
  });
};

import { COMMON_DATE_FORMAT, IMPORT_FILE_TYPES } from "constants/app.constant";
import { Notification, Tag, toast } from "components/ui";
import { HiOutlinePlusCircle } from "react-icons/hi";
import {
  TABLE_ACTION_KEYS,
  USER_NAME_BY_USER_TYPE,
} from "constants/app.constant";
import { getState } from "store";
import { PERMISSIONS } from "constants/permission.contant";
import moment from "moment";
import { APIS } from "constants/api.constant";
import appConfig from "configs/app.config";
import { postApi } from "services/CommonService";
import { ROLES } from "constants/roles.constant";

const USER_STATUS = [
  { id: 0, name: "inactive" },
  { id: 1, name: "active" },
  { id: 2, name: "deleted" },
];

export const MASTER_DATA_TYPES = [
  { label: "Plants", value: 1 },
  { label: "StorageLocations", value: 2 },
  { label: "ProductsInventory", value: 3 },
  { label: "customers", value: 4 },
  { label: "Inventory", value: 5 },
];

export const BLOCKED_DATA_TYPES = [
  { label: "Plants", value: 1 },
  { label: "StorageLocations", value: 2 },
  { label: "ProductsInventory", value: 3 },
  { label: "customers", value: 4 },
  { label: "Users", value: 5 },
  { label: "Inventory", value: 6 },
];

export const SECONDARY_SALES_TYPES = [
  { label: "Today invoice", value: 1 },
  { label: "Sale On ETA", value: 2 },
];

export const USERS_TYPES = [
  { label: "SUPER ADMIN", value: 1 },
  { label: "EXECUTIVE BOARD", value: 2 },
  { label: "GENERAL MANAGER", value: 3 },
  { label: "ZONAL MANAGER", value: 4 },
  { label: "AREA SALES MANAGER", value: 5 },
  { label: "ACCOUNTS TEAM", value: 6 },
  { label: "SALES OFFICERS", value: 7 },
];

export const EXPORT_DATA_TYPES = [
  { label: "Plant", value: 1 },
  { label: "storage locations", value: 2 },
  { label: "material", value: 3 },
  { label: "customers", value: 4 },
  { label: "Sales order", value: 5 },
  { label: "Sales invoice", value: 6 },
  { label: "Sales Based on ETA", value: 7 },
  { label: "Secondary Sales", value: 8 },
  { label: "Sales with Picklist", value: 9 },
];

export const generateDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = `userID_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  }
  localStorage.setItem("device_id", deviceId);
  return deviceId;
};

export const arrayToString = (fieldName) => {
  return (
    <>
      {[fieldName]?.length > 0
        ? [fieldName].map((str, i, arr) => {
            return (
              <div className="capitalize">{`${str} ${
                i !== [fieldName]?.length - 1 ? "," : ""
              }`}</div>
            );
          })
        : "-"}
    </>
  );
};

export const ValidateData = (data) => {

  if (data === "null" || !data) {
    return false;
  }
  return true;
};

export const getFormatedDate = (date, formatter = COMMON_DATE_FORMAT) => {
  const dateObject = moment.utc(date).local();
  const formattedDate = dateObject.format(formatter);
  return formattedDate;
};

export const getTagLabelFromObject = (value, object) => {
  const dataObj = object.find((obj) => obj?.value === value);
  return (
    <Tag
      className={`bg-${dataObj?.color}-100 text-${dataObj?.color}-600 dark:bg-${dataObj?.color}-500/20 dark:text-${dataObj?.color}-100 border-0 rounded`}
    >
      {dataObj.label}
    </Tag>
  );
};

export const chnageUserStatus = (currentValue) => {
  const statusObj = USER_STATUS.find((obj) => obj.name !== currentValue);
  if (statusObj) {
    return statusObj.id;
  }

  return null;
};

export const getAddButtonObj = ({ heading }) => {
  return [
    {
      label: heading,
      key: TABLE_ACTION_KEYS.ADD,
      icon: <HiOutlinePlusCircle />,
    },
  ];
};

export const getLabelFromObject = (array, value) => {
  let obj = array.find((obj, i, arr) => obj?.value === value);
  if (obj === undefined) {
    return "NA";
  } else {
    return obj.label;
  }
};

export const getFirstDayOfCurrMonth = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

export const getLastDayOfCurrMonth = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
};

export const getUserStatus = (value) => {
  return USER_STATUS.find((obj, i) => obj.id === value);
};

export const hasPermission = (action, pageIdentifier) => {
  let currentUserType = getState().auth?.user?.userType;
  const permissionnObj = PERMISSIONS.find(
    (obj, i, arr) => obj.pageIdentifier === pageIdentifier
  );
  return permissionnObj?.[action]?.includes(currentUserType);
};

export const getActiveUser = () => {
  let activeUserobj = USER_NAME_BY_USER_TYPE.find(
    (obj, i, arr) => obj.value === getState().auth?.user?.userType
  );
  return activeUserobj.label;
};

export const checkPermissions = (pageName, type) => {
  const { user } = getState().auth || {};
 
  if (!user) return false;
  if (user.userType === ROLES.SUPER_ADMIN && user?.roles?.length) {
    const rolesArray = JSON.parse(user?.roles || "[]");

    const page = rolesArray.find((role) => role.page === pageName);

    if (!page) return false;

    if (type === "view") return page.view;
    if (type === "addEdit") return page.addEdit;
  } else {
    return true;
  }
};

export const formatAmount = (amount) => {
  const nf = new Intl.NumberFormat("en-IN");
  return `₹ ${nf.format(amount)}`;
};

export function handleUploadExcelFiles(file, filetype) {
  let formDataToSend = new FormData();

  formDataToSend.append("file", file[0]);
  formDataToSend.append("fileType", filetype);

  postApi(APIS.IMPORT_EXCEL_FILE, formDataToSend).then(
    ({ statusCode, data, message }) => {
      if (statusCode === 200) {
        toast.push(
          <Notification type="success">
            {message}! Wait for few mins to see the changes
          </Notification>
        );
      }
    }
  );
}

export function checkForAdminRoles(roles,action,pageName,value){
   if(action==="view"){
    return roles.map((role)=>{
         if(role.page===pageName){
           if(role.view && roles.hasOwnProperty("addEdit")){
             const  updatedRoute = { ...role, view: value ,addEdit:value};
             
           }else if(role.view && !roles.hasOwnProperty("addEdit") ){
             const  updatedRoute = { ...role, view: value };

           }else{
             return roles
           }
           
         }
     })
   }else if(action==="addEdit"){
   return roles.map((role)=>{
      if(role.page===pageName){
        if(role.addEdit){
          const  updatedRoute = { ...role,addEdit:value};
          
        }else if(!role.view && roles.hasOwnProperty("addEdit") && !roles.addEdit ){
          const updatedRoute = { ...role, view: value,addEdit:value };

        }else{
          return roles
        }
        
      }
  })
   }
}

export const handleDownloadExcel = async (fileType, columnsToExport = []) => {
  try {
    const postResponse = await fetch(
      `${appConfig.apiBaseUrl}/${APIS.EXPORT_MATERIALS_EXCEL_FILE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth?.session?.token}`,
        },
        body: JSON.stringify({
          fileType,
          fields: columnsToExport,
        }),
      }
    );

    if (!postResponse.ok) {
      toast.push(
        <Notification type="error">
          {`HTTP error in POST API! Status: ${postResponse.status}`}
        </Notification>
      );
      return;
    }

    const postData = await postResponse.json();

    const filePath = postData?.data?.path;
    if (!filePath) {
      toast.push(
        <Notification type="error">
          Failed to get the file path from POST API response.
        </Notification>
      );
      return;
    }

    const getResponse = await fetch(`${appConfig.apiBaseUrl}/${filePath}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getState().auth?.session?.token}`,
      },
    });

    if (!getResponse.ok) {
      toast.push(
        <Notification type="error">
          {`HTTP error in GET API! Status: ${getResponse.status}`}
        </Notification>
      );
      return;
    }

    const blob = await getResponse.blob();

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = postData?.data?.name || "downloaded_excel_file.xlsx";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    toast.push(
      <Notification type="success">
        Excel file downloaded successfully!
      </Notification>
    );
  } catch (error) {
    toast.push(
      <Notification type="error">
        Failed to download the Excel file: {error.message}
      </Notification>
    );
  }
};

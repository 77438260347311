import { ROLES } from "./roles.constant";
import { PAGE_IDENTIFIER } from "./app.constant";

export const PERMISSIONS = [
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_LEADS,
    Add: [ROLES.SUPER_ADMIN],
    Export: [
      ROLES.EXECUTIVE_BOARD,
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_FINANCIER_SCHEME,
    Add: [ROLES.SUPER_ADMIN],
  },
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_SUPER_ADMIN,
    Add: [ROLES.SUPER_ADMIN],
  },
  {
    pageIdentifier: PAGE_IDENTIFIER.MASTER_DATA_ZONES_AND_STATES,
    Add: [ROLES.SUPER_ADMIN],
  },
  {
    pageIdentifier: PAGE_IDENTIFIER.DEALER_ORDER_REPORT,
    Export: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.EXECUTIVE_BOARD,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  {
    pageIdentifier: PAGE_IDENTIFIER.STATE_PROJECTION_SUMMARY_REPORT,
    Export: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.EXECUTIVE_BOARD,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  //// USER_MANAGEMENT_LISITING_MARKETTING_MANAGER
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_MARKETING_MANAGER,

    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },

  //// USER_MANAGEMENT_LISITING_ZONAL_HEAD
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_ZONAL_HEAD,
    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },

  //// USER_MANAGEMENT_LISITING_STATES_HEAD

  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_STATE_HEAD,

    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },

  //// USER_MANAGEMENT_LISITING_TERRIOTRY
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_TERRITORY_HEAD,

    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  //// USER_MANAGEMENT_LISITING_SEGEMENT_HEAD

  {
    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },

  //// USER_MANAGEMENT_LISITING_DEALER
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_DEALER,

    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      //   ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  //// USER_MANAGEMENT_LISITING_DEALER_MARKETING_MANAGER
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_DEALER_MARKETING,

    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      //   ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  //// USER_MANAGEMENT_LISITING_SALES
  {
    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      //   ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },

  ///// MATER_DATA_MODELS
  {
    pageIdentifier: PAGE_IDENTIFIER.LISITING_DEALER_MARKETING,
    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      //   ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  //// USER_MANAGEMENT_LISITING_SALES
  {
    pageIdentifier: PAGE_IDENTIFIER.MASTER_DATA_MODELS,
    Export: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      //   ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  ///// MATER_DATA_COMPITIOTS
  {
    pageIdentifier: PAGE_IDENTIFIER.MASTER_DATA_COMPETITORS,
    Export: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      //   ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  ///// MATER_DATA_SOURCE_ENQUIRY
  {
    pageIdentifier: PAGE_IDENTIFIER.MASTER_DATA_SOURCE_ENQUIRY,
    Export: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Add: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      //   ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Edit: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    Delete: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
    View: [
      ROLES.SUPER_ADMIN,
      ROLES.GENERAL_MANAGER,
      ROLES.ACCOUNTS_TEAM,
      ROLES.ZONAL_BUSINESS_HEAD,
    ],
  },
  ///// MASTER_DATA _CUSTOMER CATEGORY
  {
    pageIdentifier: PAGE_IDENTIFIER.MASTER_DATA_CUSTOMER_CATEGORY,
    Export: [ROLES.SUPER_ADMIN, ROLES.SUPER_ADMIN, ROLES.GENERAL_MANAGER],
    Add: [ROLES.SUPER_ADMIN, ROLES.SUPER_ADMIN, ROLES.GENERAL_MANAGER],
    Edit: [ROLES.SUPER_ADMIN, ROLES.SUPER_ADMIN, ROLES.GENERAL_MANAGER],
    Delete: [ROLES.SUPER_ADMIN, ROLES.SUPER_ADMIN, ROLES.GENERAL_MANAGER],
    View: [ROLES.SUPER_ADMIN, ROLES.SUPER_ADMIN, ROLES.GENERAL_MANAGER],
  },
];

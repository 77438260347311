import { GrMapLocation, GrPowerReset, GrUserManager } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";
import {
  FaTruck,
  FaRegMoneyBillAlt,
  FaEye,
  FaUser,
  FaChartLine,
  FaPlay,
  FaCross,
} from "react-icons/fa";
import {
  MdOutlineInventory2,
  MdOutlineAccountBalance,
  MdSupervisorAccount,
} from "react-icons/md";
import { TbBuildingFactory, TbFileInvoice } from "react-icons/tb";
import { IoIosInformationCircle, IoMdCopy } from "react-icons/io";
import {
  IoEarthSharp,
  IoStatsChart,
  IoBarChartSharp,
  IoFolderOpenOutline,
  IoPeopleSharp,
  IoFolder,
  IoMusicalNotes,
  IoPause,
} from "react-icons/io5";
import { TiThMenu, TiMap, TiSupport } from "react-icons/ti";
import { MdOutlineFileUpload } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { RiAdvertisementFill, RiAccountPinCircleFill } from "react-icons/ri";
import { FiPackage } from "react-icons/fi";
import { HiOutlineDocument } from "react-icons/hi";

export const APP_NAME = "Unicharm";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";
export const IMAGE_BASE_URL = "https://unicharm.s3.ap-south-1.amazonaws.com/";
export const IMAGES = {
  FIREWORKS: "/img/festive/fireworks.gif",
  LOHRI: "/img/festive/lohri.gif",
};

export const USER_NAME_BY_USER_TYPE = [
  { label: "Super Admin/IT", value: 1 },
  { label: "Executive Board", value: 2 },
  { label: "General Manager", value: 3 },
  { label: "Zonal Manager", value: 4 },
  { label: "Area Sales Manager", value: 5 },
  { label: "Accounts Team", value: 6 },
  { label: "Sales Officer", value: 7 },
];

export const IMPORT_FILE_TYPES = {
  PLANTS: 1,
  STORAGE_LOCATIONS: 2,
  MATERIALS: 3,
  CUSTOMERS: 4,
  INVENTORY_UPDATE: 7,
  SECONDARY_SALES: 8,
  SALES_INVOICE: 9,
  SALES_ORDER: 10,
};

export const EXPORT_FILE_TYPES = {
  PLANTS: 1,
  STORAGE_LOCATIONS: 2,
  MATERIALS: 3,
  CUSTOMERS: 4,
  SALES_ORDER: 5,
  SALES_INVOICE: 6,
  SALES_BASED_ETA: 7,
  SECONDARY_SALES: 8,
  SALES_WITH_PICKLIST: 9,
  MATERIAL_INVENTORY: 10,
  POS_ORDER: 11,
};

export const CUSTOMER_GROUP = [
  { label: "A01", value: "SS-Super Stockist" },
  { label: "A02", value: "TT-Traditional Trade" },
  { label: "A03", value: "MT-Modern Trade" },
  { label: "A04", value: "Hospital MT" },
  { label: "A05", value: "SDB MT" },
  { label: "A06", value: "SDB TT" },
  { label: "A07", value: "Amazon" },
  { label: "A08", value: "Reliance C&C" },
  { label: "A09", value: "Bharti" },
  { label: "A10", value: "Brainbees" },
  { label: "A11", value: "Walmart" },
  { label: "A12", value: "Cloudtail" },
  { label: "A13", value: "CPC" },
  { label: "A14", value: "CSD Rest of India" },
  { label: "A15", value: "Reliance Retail" },
  { label: "A16", value: "Metro" },
  { label: "A17", value: "TNPC" },
  { label: "A18", value: "UPGC" },
  { label: "A19", value: "CSR" },
  { label: "A20", value: "Export-UCIa:Grp comp" },
  { label: "A21", value: "Export-UCIa:Others" },
  { label: "A22", value: "Other Customers" },
  { label: "A23", value: "Plants for STO" },
  { label: "A24", value: "Urban SS" },
  { label: "A25", value: "Carrefour" },
  { label: "A26", value: "CSD Bhiwandi" },
  { label: "A27", value: "SDB TT Zirakpur" },
  { label: "A28", value: "SDB MT Zirakpur" },
  { label: "A29", value: "SDB TT Jammu" },
  { label: "A30", value: "SDB MT Jammu" },
  { label: "A31", value: "Customer Merchant" },
  { label: "A32", value: "AM Logistics" },
  { label: "A33", value: "SDB TT Delhi" },
  { label: "A34", value: "SDB MT Delhi" },
  { label: "A35", value: "Udaan" },
  { label: "A36", value: "SS2" },
  { label: "A37", value: "FSN Distribution" },
  { label: "A38", value: "Big Basket" },
  { label: "A39", value: "SDB MT Bhiwandi D&" },
  { label: "A40", value: "SDB MT Delhi D & R" },
  { label: "A41", value: "MT D & R" },
];

export const API_PAYLOAD_KEY = {
  MARKETTING_HEAD: "user-management-marketing-head",
  ZONAL_BUSINESS: "user-management-zonal-bussiness",
  STATE_BUSINESS: "user-management-state-bussiness",
  TERRITORY_MANAGER: "user-management-territory-manager",
  DEALER: "user-management-dealer",
  DEALER_MARKETING: "user-management-dealer-marketing",
};

export const CUSTOMER_COLUMN_OPTIONS = [
  { label: "Customer Name", value: "Customer Name", visible: true },
  { label: "Customer Code", value: "Customer Code", visible: true },
  { label: "Email", value: "Email", visible: true },
  { label: "Phone Number", value: "Phone Number", visible: true },
  { label: "General Manager", value: "General Manager", visible: true },
  { label: "Area Sales Manager", value: "Area Sales Manager", visible: true },
  { label: "Sales Officer", value: "Sales Officer", visible: true },
  { label: "Plants", value: "Plants", visible: true },
  { label: "Storage Location", value: "Storage Location", visible: true },
  {
    label: "Storage Location Code",
    value: "Storage Location Code",
    visible: true,
  },
  { label: "GSTIN", value: "GSTIN", visible: true },
  { label: "Pan Number", value: "Pan Number", visible: true },
  { label: "Payer", value: "Payer", visible: true },

  { label: "Customer Group", value: "Customer Group", visible: true },
  { label: "Credit Limit", value: "Credit Limit", visible: true },
  {
    label: "Remaining Credit Limit",
    value: "Remaining Credit Limit",
    visible: true,
  },
  { label: "Gst", value: "Gst", visible: true },
  { label: "City", value: "City", visible: true }, // backend Field as address1
  { label: "Address", value: "Address", visible: true }, // backend Field as address2
];

export const SALES_ORDER_COLUMN_OPTIONS = [
  { label: "Delivery", value: "Delivery", visible: true },
  { label: "Item", value: "Item", visible: true },
  { label: "Shipping Warehouse", value: "Shipping Warehouse", visible: true },
  { label: "Delivery Customer", value: "Delivery Customer", visible: true },
  { label: "Document Date", value: "Document Date", visible: true },
  // { label: "Product Name", value: "Product Name", visible: true },
  // { label: "Order Quantity", value: "Order Quantity", visible: true },
  // { label: "Unit Price", value: "Unit Price", visible: true },
  // {
  //   label: "Shipping Warehouse State",
  //   value: "Shipping Warehouse State",
  //   visible: true,
  // },
];

export const SALES_INVOICE_COLUMN_OPTIONS = [
  { label: "Delivery", value: "Delivery", visible: true },
  { label: "Storage location", value: "Storage location", visible: true },
  { label: "Delivery Customer", value: "Delivery Customer", visible: true },
  { label: "Issue Date", value: "Issue Date", visible: true },
  { label: "Invoice Number", value: "Invoice Number", visible: true },
  { label: "Invoice Line Number", value: "Invoice Line Number", visible: true },
];

export const SALES_BASED_UPON_ETA_OPTIONS = [
  { label: "Delivery", value: "Delivery", visible: true },
  { label: "Storage location", value: "Storage location", visible: true },
  { label: "Delivery Customer", value: "Delivery Customer", visible: true },
  { label: "Eta Date", value: "Eta Date", visible: true },
  { label: "Invoice Number", value: "Invoice Number", visible: true },
  { label: "Invoice Line Number", value: "Invoice Line Number", visible: true },
];
export const SECONDARY_SALES_INVOICE_OPTIONS = [
  { label: "CompInvNo", value: "CompInvNo", visible: true },
  { label: "CompInvDate", value: "CompInvDate", visible: true },
  { label: "Sales document", value: "Sales document", visible: true },
  { label: "Stockist", value: "Stockist", visible: true },
  { label: "GST", value: "GST", visible: true },
];

export const EXECUTIVE_BOARD_COLUMN_OPTIONS = [
  { label: "Name", value: "Name", visible: true },
  { label: "Employee Code", value: "Employee Code", visible: true },
  { label: "Email", value: "Email", visible: true },
  { label: "Phone Number", value: "Phone Number", visible: true },
];

export const GENERAL_MANAGER_COLUMN_OPTIONS = [
  { label: "Name", value: "Name", visible: true },
  { label: "Employee Code", value: "Employee Code", visible: true },
  { label: "Email", value: "Email", visible: true },
  { label: "Phone Number", value: "Phone Number", visible: true },
  { label: "Plants", value: "Plants", visible: true },
];

export const ZONAL_MANAGER_COLUMN_OPTIONS = [
  { label: "Name", value: "Name", visible: true },
  { label: "Employee Code", value: "Employee Code", visible: true },
  { label: "Email", value: "Email", visible: true },
  { label: "Phone Number", value: "Phone Number", visible: true },
  { label: "Plants", value: "Plants", visible: true },
];
export const AREA_SALES_MANAGER_COLUMN_OPTIONS = [
  { label: "Name", value: "Name", visible: true },
  { label: "Employee Code", value: "Employee Code", visible: true },
  { label: "Email", value: "Email", visible: true },
  { label: "Phone Number", value: "Phone Number", visible: true },
  { label: "Plants", value: "Plants", visible: true },
  { label: "Storage Location", value: "Storage Location", visible: true },
  {
    label: "Storage Location Code",
    value: "Storage Location Code",
    visible: true,
  },
];

export const ACCOUNTS_TEAM_COLUMN_OPTIONS = [
  { label: "Name", value: "Name", visible: true },
  { label: "Employee Code", value: "Employee Code", visible: true },
  { label: "Email", value: "Email", visible: true },
  { label: "Phone Number", value: "Phone Number", visible: true },
];

export const SALES_OFFICERS_COLUMN_OPTIONS = [
  { label: "Name", value: "Name", visible: true },
  { label: "Employee Code", value: "Employee Code", visible: true },
  { label: "Email", value: "Email", visible: true },
  { label: "Phone Number", value: "Phone Number", visible: true },
  { label: "Plants", value: "Plants", visible: true },
  { label: "Storage Location", value: "Storage Location", visible: true },
  {
    label: "Storage Location Code",
    value: "Storage Location Code",
    visible: true,
  },
];
export const CONDITION_TYPE = [
  { label: "Actual Price", value: "ZP01" },
  { label: "Discount Price", value: "ZD00" },
];
export const PRIORITY_SKU_OPTIONS = [
  { label: "Active", value: true },
  { label: "Deactive", value: false },
];
export const USER_STATUS_OPTIONS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Deactive", value: "BLOCKED" },
];
export const HOME_SCREEN_VISIBILITY = [
  { label: "True", value: true },
  { label: "False", value: false },
];

export const ACTION_ICON_SIZE = ".875rem";

export const NAVIGATION_ICON = {
  ORDER: <FiPackage />,
  INVOICE: <TbFileInvoice />,
  REPORT: <HiOutlineDocument />,
  ADVERTISEMENT: <RiAdvertisementFill />,
  CROSS_BUTTON: <ImCross />,
  PAUSE: <IoPause />,
  PLAY_ICON: <FaPlay />,
  COPY_ICON: <IoMdCopy />,
  MATERIAL: <MdOutlineInventory2 />,
  FILE_UPLOAD: <MdOutlineFileUpload />,
  STORAGE_LOCATIONS: <GrMapLocation />,
  MODAL: <FaTruck />,
  USER: <FaUser />,
  ZONES_STATES: <IoEarthSharp />,
  FINANCER: <FaRegMoneyBillAlt />,
  COMPITIORS: <FaEye />,
  APP_DEFAULTS: <TiThMenu />,
  GRAPH: <IoBarChartSharp />,
  MONTHLY_STAT: <IoStatsChart />,
  PLANTS: <TbBuildingFactory />,
  LEAD: <FaChartLine />,
  ENQUIRY: <IoIosInformationCircle />,
  VISIT_PURPOSE: <IoFolderOpenOutline />,
  CUSTOMER_CATEGORY: <IoFolder />,
  DEALER_SUMMARY: <TiSupport />,
  STATE_PROJECTION_SUMMARY: <TiMap />,
  MANAGER: <RiAccountPinCircleFill />,
  ZONAL_MANAGER: <IoPeopleSharp />,
  ACCOUNTS_TEAM: <MdOutlineAccountBalance />,
  SALES_MANAGER: <MdSupervisorAccount />,
};

export const TABLE_ACTION_KEYS = {
  EDIT: "Edit",
  DELETE: "Delete",
  ADD: "Add",
  VIEW: "View",
  RESET: "Reset",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  NAVIGATE: "Navigate",
  DOWNLOAD: "Download",
  IMPORT: "Import",
  CHANGE_PASSWORD: "ChangePassword",
  STATUS: "status",
  VIEW_MORE_INFO: "viewMoreInfo",
  EXPORT: "Export",
  ACTIVITY_LOG: "activityLog",
  HOMESCREEN_VISIBLE: "homeScreenVisibility",
};

export const ACTIVE_INACTIVE_STATUS = [
  {
    label: "ACTIVE",
    identifier: 2,
    key: "ACTIVE",
    value: true,
  },
  {
    label: "BLOCKED",
    identifier: 3,
    key: "BLOCKED",
    value: false,
  },
];

export const HOMESCREEN_VISIBILITY_STATUS = [
  {
    label: "True",
    key: true,
    value: true,
  },
  {
    label: "False",
    key: true,
    value: false,
  },
];

export const TOAST_TIMING = 4000;

export const COMMON_DATE_FORMAT = "DD MMMM YYYY hh:mm A";

export const INITIAL_PAGE_COUNT = 25;

export const NO_DATA_FOUND = "-";

export const NAME_COLUMN_SIZE = "";

export const USER_MANAGEMNT_COMMON_SEARCH_INFO = "Search by name";

export const USER_MANAGEMNT_ACTION_CONSTANT = [
  { label: "Edit", key: TABLE_ACTION_KEYS.EDIT, toolTip: "Edit" },
];
export const RESET_ACTION_CONSTANT = [
  { label: "Reset", key: TABLE_ACTION_KEYS.RESET, toolTip: "Reset Passowrd" },
];

export const LEAD_MODAL_ENQUIRED_CONSTANT = [
  {
    label: <FaEye style={{ fontSize: ACTION_ICON_SIZE }} />,
    key: TABLE_ACTION_KEYS.VIEW_MORE_INFO,
    toolTip: "Model Details",
  },
];

export const TOAST_MESSAGE = {
  USER_SAVED: "User saved successfully!",
  USER_UPADTE: "User updated successfully!",
};

export const PAGE_IDENTIFIER = {
  LISITING_LEADS: "lisiting-leads",
  LISITING_MARKETING_MANAGER: "lisiting-marketing-manager",
  LISITING_ZONAL_HEAD: "lisiting-zonal-head",
  LISITING_STATE_HEAD: "lisiting-state-head",
  LISITING_TERRITORY_HEAD: "lisiting-territory-head",
  LISITING_DEALER: "lisiting-dealer",
  DEALER_ORDER_REPORT: "dealer-order-report",
  STATE_PROJECTION_SUMMARY_REPORT: "state-projection-summary-report",
  LISITING_DEALER_MARKETING: "lisiting-dealer-marketing",
  LISITING_SUPER_ADMIN: "lisiting-config-admin",
  LISITING_FINANCIER_SCHEME: "lisiting-financier-scheme",
  MASTER_DATA_MODELS: "master-data-models",
  MASTER_DATA_ZONES_AND_STATES: "master-data-zones-states",
  MASTER_DATA_FINANCE_SCHEME: "master-data-finance-scheme",
  MASTER_DATA_COMPETITORS: "master-data-competitors",
  MASTER_DATA_APP_DEFAULT: "master-data-app-default",
  MASTER_DATA_SOURCE_ENQUIRY: "master-data-source-enquiry",
  MASTER_DATA_CUSTOMER_CATEGORY: "master-data-customer-category",
};

export const FILTER_CONSTANT = {
  isSearch: {
    placeholder: USER_MANAGEMNT_COMMON_SEARCH_INFO,
    toolTipText: USER_MANAGEMNT_COMMON_SEARCH_INFO,
  },
  isCalendarFilter: {
    type: "date-range-picker",
    placeholder: "Select date range",
    filterKey1: "startDate",
    filterKey2: "endDate",
  },

  isResetButton: {
    label: "Reset",
    icon: <GrPowerReset />,
    filterKey: {
      statuses: null,
      startDate: null,
      endDate: null,
      prioritySku: null,
      customerGroup: null,
      material: null,
      customer: null,
      plant: null,
      storageLocation: null,
      conditionType:null,
    },
  },
  isFilterButton: {
    label: "Filter",
    icon: <CiFilter />,
    filters: [
      {
        component: "select",
        options: USER_STATUS_OPTIONS,
        className: "w-full",
        placeholder: "Select Status",
        filterKey: "statuses",
      },
      {
        component: "select",
        options: PRIORITY_SKU_OPTIONS,
        className: "w-full",
        placeholder: "Select Priority Sku's ",
        filterKey: "prioritySku",
      },
      {
        component: "select",
        options: CONDITION_TYPE,
        className: "w-full",
        placeholder: "Select Condition Type ",
        filterKey: "conditionType",
      },
      {
        component: "select",
        options: CUSTOMER_GROUP.map((item) => ({
          label: `${item?.value || ""} - ${item?.label || ""}`,
          value: item?.label || "",
        })),
        className: "w-full",
        placeholder: "Select Customer Group ",
        filterKey: "customerGroup",
      },
      {
        component: "material-async-select",
        className: "w-full",
        placeholder: "Select Material",
        filterKey: "material",
      },
      {
        component: "customer-async-select",
        className: "w-full",
        placeholder: "Select Customer",
        filterKey: "customer",
      },
      {
        component: "plants-async-select",
        className: "w-full",
        placeholder: "Select Plant",
        filterKey: "plant",
      },
      {
        component: "storage-location-async-select",
        className: "w-full",
        placeholder: "Select Storage Location",
        filterKey: "storageLocation",
      },
    ],
  },
};

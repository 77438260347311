import React, { useState } from "react";
import FilterBar from "./filterBar";
import MotionFilters from "./motionFilters";
import { Button } from "components/ui";

const Header = ({
  search,
  setSearch,
  setFilterValue,
  filterValue,
  FilterMenu,
  handleBtnClick,
  btnLabel,
  placeholder,
  handleUploadBtn,
  customerCreditLimit,
  importExcelBtnLabel,
  handleImportExcel,
  btnIcon,
  initialColumns,
  nameOfPage,
  alwaysShowFilter,
  pageName,
}) => {
  const [motionFilters, setMotionFilters] = useState(false);
  const onFilterClick = () => {
    setMotionFilters(!motionFilters);
  };

  return (
    <div>
      <FilterBar
        search={search}
        FilterMenu={FilterMenu}
        setSearch={setSearch}
        placeholder={placeholder}
        setFilterValue={setFilterValue}
        alwaysShowFilter={alwaysShowFilter}
        filterValue={filterValue}
        onFilterClick={onFilterClick}
        motionFilters={motionFilters}
        handleBtnClick={handleBtnClick}
        btnLabel={btnLabel}
        handleUploadBtn={handleUploadBtn}
        importExcelBtnLabel={importExcelBtnLabel}
        handleImportExcel={handleImportExcel}
        customerCreditLimit={customerCreditLimit}
        btnIcon={btnIcon}
        initialColumns={initialColumns}
        nameOfPage={nameOfPage}
        pageName={pageName}
      />

      {(motionFilters || alwaysShowFilter) && (
        <MotionFilters
          motionFilters={motionFilters || alwaysShowFilter}
          FilterMenu={FilterMenu}
          setFilterValue={setFilterValue}
          filterValue={filterValue}
        />
      )}
    </div>
  );
};
export default Header;

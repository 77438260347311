import React from "react";
import TableSearchBar from "../TableSearchBar";
import { DatePicker, Button } from "components/ui";
import { GrPowerReset } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";
import CheckBoxDropdown from "../checkBoxDropdown";
import { FILTER_CONSTANT } from "constants/app.constant";
import dayjs from "dayjs";
import { checkPermissions } from "utils/utils";

const FilterBar = ({
  search,
  FilterMenu,
  setSearch,
  setFilterValue,
  filterValue,
  onFilterClick,
  motionFilters,
  handleBtnClick,
  isCreateBtn,
  btnLabel,
  placeholder,
  customerCreditLimit,
  btnIcon,
  initialColumns,
  nameOfPage,
  alwaysShowFilter,
  pageName,
}) => {
  
  return (
    <div className="flex flex-wrap w-full items-center justify-between ">
      <div className="flex w-full gap-4 sm:w-8/12 md:w-8/12 lg:w-8/12 xl:w-8/12 pb-2">
        {initialColumns && (
          <CheckBoxDropdown nameOfPage={nameOfPage} columns={initialColumns} />
        )}

        {FilterMenu?.isSearch && (
          <div className="flex w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <TableSearchBar
              search={search}
              placeholder={placeholder}
              onChange={(query) => setSearch(query)}
              SearchBarInfo={FilterMenu?.isSearch?.toolTipText}
            />
          </div>
        )}
        {FilterMenu?.isCalendarFilter && (
          <div className="flex w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <DatePicker.DatePickerRange
              size="sm"
              dateViewCount={2}
              value={[
                filterValue?.[FilterMenu?.isCalendarFilter?.filterKey1] ||
                  dayjs().startOf("day").toDate(),
                filterValue?.[FilterMenu?.isCalendarFilter?.filterKey2] ||
                  dayjs().endOf("day").toDate(),
              ]}
              placeholder={FilterMenu?.isCalendarFilter?.placeholder}
              inputFormat="DD/MM/YYYY"
              onChange={(dateArray) => {
                setFilterValue({
                  ...filterValue,
                  [FilterMenu?.isCalendarFilter?.filterKey1]: dateArray[0],
                  [FilterMenu?.isCalendarFilter?.filterKey2]: dateArray[1],
                });
              }}
            />
          </div>
        )}
      </div>
      <div className="flex w-full sm:w-3/12  md:w-1/12 lg:w-1/12 xl:w-1/12 justify-end pb-2">
        {!alwaysShowFilter &&
          FilterMenu?.isFilterButton &&
          !customerCreditLimit && (
            <Button
              size="sm"
              className={`mr-2 ${motionFilters && "text-[#F97316]"}`}
              onClick={onFilterClick}
              icon={<CiFilter color={motionFilters && "#F97316"} />}
            >
              Filter
            </Button>
          )}
        {FilterMenu?.isResetButton && (
          <Button
            size="sm"
            onClick={() => {
              setFilterValue(FilterMenu?.isResetButton?.filterKey);
              setSearch("");
            }}
            icon={<GrPowerReset />}
          >
            Reset
          </Button>
        )}
        {checkPermissions(pageName, "addEdit") && btnLabel && (
          <Button
            variant="solid"
            size="sm"
            className="ml-2"
            onClick={handleBtnClick}
            icon={btnIcon || ""}
          >
            {btnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
export default FilterBar;

import { THEME_ENUM } from "constants/theme.constant";
import { SIZES } from "components/ui/utils/constant";

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */



export const themeConfig = {
  themeColor: "orange",
  direction: THEME_ENUM.DIR_LTR,
  mode: THEME_ENUM.MODE_LIGHT,
  locale: "en",
  primaryColorLevel: 500,
  cardBordered: false,
  panelExpand: false,
  controlSize: SIZES.MD,
  navMode: THEME_ENUM.NAV_MODE_LIGHT,
  layout: {
    type: THEME_ENUM.LAYOUT_TYPE_DECKED,
    sideNavCollapse: false,
  },
};

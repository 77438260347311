export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const UNICHARM_AUTH_HEADER_KEY = "Basic c21saWNybTpTbWxpQENSTTI0";

export const APIS = {
  GET_POS_ORDERLIST:"/admin/listPunchedOrders",
  AUTH: {
    LOGIN: "/admin/login",
    // LOGIN: "User/Login",
  },
  RESET_PASSWORD:"admin/resetPasswordUser",
  BLOCK_ORDER:"/admin/appSettings",
  GET_BLOCK_ORDER_DATA:"/admin/settings",
  PRIMARYT_SALES: {
    GET_PRIMARY_SALES: "/admin/primarySales",
  },
  SALES_INVOICE: {
    GET_SALES_INVOICE: "/admin/salesInvoice",
  },
  SECONDARY_SALES: {
    GET_SECONDARY_SALES: "/admin/secondarySales",
  },
  SALES_BASED_ON_PICKLIST: {
    GET_SALES_BASED_ON_PICKLIST: "/admin/salesBasedPickList",
  },

  EXPORT_MATERIALS_EXCEL_FILE: "admin/exportFile",
  EXPORT_SALES_ORDER_EXCEL_FILE: "bucket/Sales-Order-data.xlsx",
  EXPORT_SALES_INVOICE_EXCEL_FILE: "bucket/Sales-Invoice-data.xlsx",
  EXPORT_SALES_BASED_ON_ETA_EXCEL_FILE: "bucket/Sales-Invoice-ETA-data.xlsx",
  EXPORT_SECONDARY_SALES_EXCEL_FILE: "bucket/Secondary-Sales-data.xlsx",
  EXPORT_SALES_WITH_PICKLIST_EXCEL_FILE: "bucket/Sales-With-Picklist-data.xlsx",
  LEADS: {
    GET_LEADS: "Lead/GetAllLeads",
  },
  GENERATE_LEAD: "Lead/GenerateLead",
  USERS: {
    EDIT_USER: "/admin/addEditUsers",
    // CREATE_USER: "User/CreateUser",
    // UPDATE_ADMIN: "User/UpdateUser",
  },
  IMPORT_EXCEL_FILE: "/admin/importFile",
  EXPORT_EXCEL_FILE: "/admin/importFile",
  ADVERTISEMENT_LIST: "advertisement/get",
  ADVERTISEMENT: {
    CREATE_ADVERTISEMENT: "advertisement/create",
    EDIT_ADVERTISEMENT: "advertisement/edit",
  },
  PLAYLIST_LIST: "playlist/get",
  PLAYLIST: {
    EDIT_PLAYLIST: "playlist/edit",
    CREATE_PLAYLIST: "playlist/create",
    ADD_PLAYLIST: "playlist/addSong",
  },
  LIST_DATA: "/admin/listData",
  PLANTS_CATEGORIES: {
    ADD_EDIT_PLANTS: "/admin/addEditPlants",
  },

  CUSTOMER_CATEGORIES: {
    ADD_EDIT_CUSTOMER: "/admin/addEditCustomer",
    EXPORT_EXCEL_FILE: "bucket/Customers-data.xlsx",
  },
  HOT_PRODUCTS: {
    HOT_PRODUCTS_LISTING: "/admin/hotProducts",
    ADD_EDIT_HOT_PRODUCTS: "/admin/hotProducts",
  },

  INVENTORY_REPORT: {
    ADD_EDIT_INVENTORY_REPORT: "/admin/addEditInventory",
  },
  BLOCK_DATA: "/admin/blockData",
  STORAGE_LOCATIONS_CATEGORIES: {
    ADD_EDIT_STORAGE_LOCATIONS: "/admin/addEditStorageLocations",
  },
  INVENTORY_CATEGORIES: {
    ADD_EDIT_INVENTORY: "/admin/addEditProducts",
  },

  ADMIN: {
    CREATE_ADMIN: "/admin/create",
    DELETE_ADMIN: "/admin/delete",
    EDIT_ADMIN: "/admin/editAdmin",
  },

  ADMIN_LIST: "/admin/getAdmin",
  LIST_USERS: "/admin/users",
 

  CHANGE_PASSWORD: "/admin/changePassword",
};
